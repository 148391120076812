li  a:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    background-color: #e2d258;
    width: 0;
    height: 2px; /* Adjust height as needed */
    transition: width 0.3s ease, left 0.3s ease;
  }
  
  li:hover a:after {
    width: 100%;
    left: 0;
  }